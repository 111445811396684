<template>
  <div class="d-flex flex-column my-5">
    <div class="spacer"></div>

    <div id="login-page">
      <div id="right" class="w-100">
        <p><router-link to="/"><font-awesome-icon :icon="['fas', 'chevron-left']"/> Terug</router-link></p>
        <h1 class="h2 font-weight-normal">Privacyverklaring</h1>

        <div class=WordSection1>
          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>MNNU,
gevestigd <span style='background:darkgray;mso-highlight:darkgray'>aan
Heidelberglaan 15 3584 CS Utrecht</span>, is verantwoordelijk voor de
verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring. </span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>Contactgegevens:</span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
background:darkgray;mso-highlight:darkgray'>https://www.mnnu.nl</span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
background:darkgray;mso-highlight:darkgray'>Heidelberglaan 15 3584 CS Utrecht</span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
background:darkgray;mso-highlight:darkgray'>088 481 8181</span><span
            style='font-size:10.0pt;font-family:"Arial",sans-serif'></span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <h3><a name="_Toc72782310"></a><a name="_Toc72782372"><span style='mso-bookmark:
_Toc72782310'>Persoonsgegevens die wij verwerken</span></a></h3>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>MNNU
verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of
omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de
persoonsgegevens die wij verwerken:</span></p>

          <p class=MsoNormal>- Voor- en achternaam.</p>

          <p class=MsoNormal>- Geslacht.</p>

          <p class=MsoNormal>- Geboortedatum.</p>

          <p class=MsoNormal>- Geboorteplaats.</p>

          <p class=MsoNormal>- Adresgegevens.</p>

          <p class=MsoNormal>- Telefoonnummer.</p>

          <p class=MsoNormal>- E-mailadres.</p>

          <p class=MsoNormal>- Overige persoonsgegevens die u actief verstrekt
            bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie
            en telefonisch.</p>

          <p class=MsoNormal>&nbsp;</p>

          <h3><a name="_Toc72782311"></a><a name="_Toc72782373"><span style='mso-bookmark:
_Toc72782311'>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</span></a></h3>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>MNNU
verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van u:</span></p>

          <p class=MsoNormal>- Seksuele leven, mits dit van toepassing is voor de
            behandeling.</p>

          <p class=MsoNormal>- Gezondheid, mits zelf verstreken of door de behandelaar
            ingevoerd met uw toestemming.</p>

          <p class=MsoNormal>- Gegevens van personen jonger dan 16 jaar Onze website
            en/of dienst heeft niet de intentie gegevens te verzamelen over
            websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van
            ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16
            is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten
            van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld
            worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij
            zonder die toestemming persoonlijke gegevens hebben verzameld over een
            minderjarige, neem dan contact met ons op via <span style='background:darkgray;
mso-highlight:darkgray'>privacy@mnnu.nl</span>, dan verwijderen wij deze
            informatie.</p>

          <p class=MsoNormal>- Burgerservicenummer (BSN)</p>

          <p class=MsoNormal>&nbsp;</p>

          <h3><a name="_Toc72782312"></a><a name="_Toc72782374"><span style='mso-bookmark:
_Toc72782312'>Met welk doel en op basis van welke grondslag wij
persoonsgegevens verwerken</span></a></h3>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>MNNU
verwerkt uw persoonsgegevens voor de volgende doelen:</span></p>

          <p class=MsoNormal>- Het afhandelen van uw betaling en behandeling.</p>

          <p class=MsoNormal>- U te kunnen bellen of e-mailen indien dit nodig is om onze
            dienstverlening uit te kunnen voeren.</p>

          <p class=MsoNormal>- U te informeren over wijzigingen van onze diensten en
            producten.</p>

          <p class=MsoNormal>- U de mogelijkheid te bieden een account aan te maken.</p>

          <p class=MsoNormal>- Om goederen en diensten bij u af te leveren.</p>

          <p class=MsoNormal>- Om onze zorg te blijven verlenen.</p>

          <p class=MsoNormal>- MNNU verwerkt ook persoonsgegevens als wij hier wettelijk
            toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze
            belastingaangifte en declaraties bij de zorgverzekering. </p>

          <p class=MsoNormal>&nbsp;</p>

          <h3><a name="_Toc72782313"></a><a name="_Toc72782375"><span style='mso-bookmark:
_Toc72782313'>Grondslag</span></a></h3>

          <p class=MsoNormal>Als u gebruik wilt maken van onze applicatie bent u
            verplicht om uw gegevens in te voeren in de applicatie. U bent tevens niet
            verplicht om deze applicatie te gebruiken en kunt ook bij uw behandelaar uw
            gegevens achterlaten. De behandelaar zal dan uw gegevens in het EPD zetten. Het
            gebruik van het EPD bent u echter wel verplicht, omdat het MNNU moet voldoen
            aan haar wettelijke verplichtingen.</p>

          <p class=MsoNormal>&nbsp;</p>

          <p class=MsoNormal>De belangen van de gegevensverwerking voor de betrokkene
            zijn als volgt: </p>

          <p class=MsoNormal>Het MNNU zal gegevens moeten verwerken en moeten back-uppen
            om een degelijke applicatie aan te kunnen bieden aan u als gebruiker. Daarnaast
            moet het MNNU persoonsgegevens verwerken om kosten te kunnen declareren bij de
            zorgverzekering en om een goede behandeling aan te kunnen bieden. Dit valt dan
            ook onder het gerechtvaardigd belang volgens de grondslag 1 (toestemming), 3
            (wettelijke verplichting).</p>

          <p class=MsoNormal>&nbsp;</p>

          <h3><a name="_Toc72782314"></a><a name="_Toc72782376"><span style='mso-bookmark:
_Toc72782314'>Geautomatiseerde besluitvorming</span></a></h3>

          <p class=MsoNormal>Bij het MNNU en in de applicatie wordt er (vooralsnog) geen
            gebruik gemaakt van geautomatiseerde besluitvorming, profilering of big data.
            Bedoelend hiermee is dat er een gebruik gemaakt wordt van computerprogramma's
            of -systemen om besluiten te nemen, zonder dat daar een mens (bijvoorbeeld een
            medewerker van MNNU) tussen zit. </p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <h3><a name="_Toc72782315"></a><a name="_Toc72782377"><span style='mso-bookmark:
_Toc72782315'>Hoe lang we persoonsgegevens bewaren</span></a></h3>

          <p class=MsoNormal>MNNU bewaart uw persoonsgegevens niet langer dan strikt
            nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld. Wij
            hanteren de volgende bewaartermijnen voor de volgende (categorieën) van
            persoonsgegevens: 20 jaar volgens de <span class=SpellE>De</span> Wet op de
            Geneeskundige Behandelingsovereenkomst (WGBO).</p>

          <p class=MsoNormal>&nbsp;</p>

          <h3><a name="_Toc72782316"></a><a name="_Toc72782378"><span style='mso-bookmark:
_Toc72782316'>Delen van persoonsgegevens met derden</span></a></h3>

          <p class=MsoNormal>MNNU verkoopt uw gegevens niet aan derden en verstrekt deze
            uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u
            of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens
            verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen
            voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens.
            MNNU blijft verantwoordelijk voor deze verwerkingen.</p>

          <p class=MsoNormal>&nbsp;</p>

          <h3><a name="_Toc72782317"></a><a name="_Toc72782379"><span style='mso-bookmark:
_Toc72782317'>Cookies, of vergelijkbare technieken, die wij gebruiken</span></a></h3>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>MNNU
gebruikt alleen technische en functionele cookies. En analytische cookies die
geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij
het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of
smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische
werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website
naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen
wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door
uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
instellingen van uw browser verwijderen.</span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <h3><a name="_Toc72782318"></a><a name="_Toc72782380"><span style='mso-bookmark:
_Toc72782318'>Gegevens inzien, aanpassen of verwijderen</span></a> </h3>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>U
heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
verwijderen. Dit kunt u zelf doen via de persoonlijke instellingen van uw
account. Daarnaast heeft u het recht om uw eventuele toestemming voor de
gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw
persoonsgegevens door ons bedrijf en heeft u het recht op
gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen
om de persoonsgegevens die wij van u beschikken in een computerbestand naar u
of een ander, door u genoemde organisatie, te sturen. Tevens kunt ook via de
instellingen van uw account deze gegevens downloaden. Wilt u gebruik maken van
uw recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heeft u andere
vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd
verzoek naar privacy@mnnu.nl. </span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>Om
er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u
een kopie van uw identiteitsbewijs bij het verzoek mee te sturen. Maak in deze
kopie uw pasfoto, MRZ (machine <span class=SpellE>readable</span> zone, de
strook met nummers onderaan het paspoort), paspoortnummer en
Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. MNNU zal
zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek
reageren. MNNU wil u er tevens op wijzen dat u de mogelijkheid hebt om een
klacht in te dienen bij de nationale toezichthouder, de Autoriteit
Persoonsgegevens. Dat kan via de volgende link: </span><a
            href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"><span
            style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-theme-font:major-fareast'>https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</span></a><span
            style='font-size:10.0pt;font-family:"Arial",sans-serif'></span></p>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

          <h3><a name="_Toc72782319"></a><a name="_Toc72782381"><span style='mso-bookmark:
_Toc72782319'>Hoe wij persoonsgegevens beveiligen</span></a></h3>

          <p><span style='font-size:10.0pt;font-family:"Arial",sans-serif'>MNNU
neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om
misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en
ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens
niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact
op met onze klantenservice of via privacy@mnnu.nl.</span></p>
        </div>
      </div>
    </div>

    <div id="footer-links" class="mt-4 mb-3">
      <span style="font-size: 0.8rem;">
        <router-link to="/terms" class="text-reset" >Voorwaarden</router-link> |
        <router-link to="/privacy" class="text-reset" >Privacy</router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms'
}
</script>

<style lang="scss">
.spacer {
  flex: 1 1 0;
}

#footer-links {
  text-align: center;
  color: #fff;
  flex: 1 1 0;
}
</style>
